$primary-color: #3d3d3d;
$button-color: #00a4ea;
$accent-color: #ffc107;
$text-color: #333;
$background-color: #f8f9fa;

$background-color-b: black;
$high-emphasis: white;
$med-emphasis: #d3d3d3;
$low-emphasis: #454545;
$panel-bg: #121212;
$colored-emphasis: #00abff;

$success: #17b649;
$warning: #d48d29;
$danger: #c1251f;
$info: #1ba0c9;
$muted: #777;

$main-bg: #0c0c0c;
$sidebar-bg: #020202;

$button-bg-hover: #454545;
$button-bg: #343434;
$button-bg-darker: #0c0c0c;
$divider-bg: #212020;
$link-color: #0080ff;
$dark-hover-bg: #0000002a;

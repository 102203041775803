@import "../styles/colors";

.footer {
  background-color: $panel-bg;
  color: white;
  a {
    text-decoration: none;
    color: white;
    &:hover {
      color: $button-color; // Correct hover effect
    }
  }

  .links {
    line-height: 3rem;
    max-width: 600px;
    justify-content: space-between;
    width: 100%;
  }

  .footer-links-container {
    padding: 20px 20px;
  }

  .btm {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid $low-emphasis;
    gap: 20px;
    .divider {
      min-width: 1px;
      min-height: 1rem;
      background-color: $low-emphasis;
    }
    .socials {
      gap: 15px;
    }
    .social-icon {
      width: 16px;
      height: auto;
      object-fit: cover;
    }
    .lin {
      border: 0.1px solid white;
      border-radius: 2px;
    }
  }
}

@media (max-width: 1200px) {
  .footer {
    .links {
      // max-width: 100px;
    }
  }
}

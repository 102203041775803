@import "../../styles/colors.scss";

.loading {
  background-color: $main-bg;
  color: $high-emphasis;
  width: 100%;
  height: 100%;
  margin-top: auto;
  .logo {
    position: absolute;
    width: 30px;
    height: auto;
    top: 10px;
    left: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    color: $high-emphasis;
    font-weight: 600;
  }

  .subnote {
    font-size: 14px;
    font-weight: normal;
    color: $low-emphasis;
    margin-top: 20px;
  }

  .progress {
    position: relative;
  }
}

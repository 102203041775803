@import "../styles/colors";

.sub-container {
  padding: 20px;
  h2 {
    margin: 10px 0 7px;
  }
  .reconnect-org {
    p {
      font-size: 14px;
    }
    @media (max-width: 768px) {
      text-align: center;
      align-items: center;
    }
  }

  .cta-btn {
    color: white !important;
    font-size: 14px;
    width: fit-content;
    i {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .subscription-section {
    gap: 20px;
    h4 {
      margin: 0;
    }
    p {
      margin-bottom: 8px;
      font-size: 14px;
    }

    .subscription-title {
      gap: 10px;
      margin-bottom: 15px;
    }

    .base-sec {
      width: 100%;
      max-width: 900px;
      .credit-usage-bar {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 6px;
        height: 15px;
        width: 100%;
        margin: 5px 0;

        .usage-bar-fill {
          height: 100%;
          border-radius: 6px;
          transition: width 0.5s ease-in-out; // Smooth transition

          &.success {
            background-color: $success;
          }
          &.warning {
            background-color: $warning;
          }
          &.danger {
            background-color: $danger;
          }
        }
      }

      p {
        margin: 5px 0;
        font-size: 0.9rem;

        .bold {
          font-weight: 700;
        }
      }
    }

    .deets {
      gap: 30px;
      width: 100%;
    }

    .sub-deet {
      gap: 5px;
      .manage-sub {
        margin-top: 10px;

        @media (max-width: 768px) {
          text-align: center;
          button {
            margin: 0 auto;
          }
        }
      }
    }

    .manage-subscription-btn {
      font-weight: 600;
      width: fit-content;
      margin: 10px 0;
      gap: 10px;
    }

    .bold {
      font-weight: 700;
    }

    .no-payment {
      color: $warning;
    }

    .payment-detected {
      color: $success;
    }

    .note {
      &.purchased-info {
        gap: 3px;
        margin: 8px 0 10px;
        // color: #555;
        margin-bottom: 10px;
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      &.purchase-info {
        margin-top: 10px;
      }
    }

    .error-message {
      color: $danger; /* Bootstrap danger color */
      font-size: 0.9rem;
      margin-top: 5px;
    }

    .credits-sec {
      gap: 10px;
      // font-size: 15px;
    }

    .buy-credits {
      input {
        padding-left: 22px;
      }

      .pur-mn {
        gap: 8px;
      }

      .buy-more-btn {
        gap: 10px;
        font-weight: 600;
        width: fit-content;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      width: fit-content;
    }

    .dollar-sign {
      position: absolute;
      left: 10px; /* Adjust spacing as needed */
      font-size: 0.9rem;
      color: $med-emphasis;
    }
  }

  .note {
    // margin-top: 10px;
    font-size: 0.9rem;
    color: $muted;
  }
}

@media (max-width: 768px) {
  .subscription-section {
    .buy-credits {
      // flex-direction: column;
      align-items: flex-start;

      input {
        width: 100%;
      }

      button {
        width: 100%;
      }
    }
  }
}

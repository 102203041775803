@import "../styles/colors";

.login-container {
  max-width: 300px;
  padding: 40px 50px;
  margin: 50px 10px;
  text-align: center;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  border-radius: 10px;
  gap: 20px;
  background-color: $panel-bg;
  color: $high-emphasis;

  h3 {
    margin: 0px;
  }
  .logo {
    width: 50px;
    height: auto;
    object-fit: cover;
    border-radius: 50%;
  }
  .github-btn {
    border: 1px solid $med-emphasis;
    background-color: transparent;
    border-radius: 10px;
    gap: 10px;
    margin: 20px 0 0;
    max-width: 300px; // Fixed width for buttons
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
    color: $high-emphasis;
    font-size: 15px;
    font-weight: 600;

    img {
      width: 30px;
      height: auto;
    }

    &:hover {
      background-color: rgb(68, 68, 68);
    }
  }
  p {
    font-size: 13px;
    color: rgb(129, 129, 129);
    a {
      color: $button-color;
    }
  }
}

@media (max-width: 768px) {
  .login-container {
    padding: 25px;
  }
}

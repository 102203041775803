@import "../styles/colors";

.setup-container {
  color: $high-emphasis;
  font-size: 14px;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  code {
    font-weight: 700;
    background-color: rgba($button-color, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
    color: $button-color;
  }

  .guide-section {
    border-radius: 12px;
    margin-bottom: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width: 100%;

    h2 {
      margin-top: 0;
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }

  .getting-started {
    a {
      color: $link-color;
      text-decoration: underline;
    }
  }

  .warning-alert {
    background-color: rgba($warning, 0.1);
    color: $high-emphasis;
    padding: 12px 16px;
    border-radius: 8px;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    width: fit-content;
    box-sizing: border-box;

    &::before {
      content: "⚠️";
      margin-right: 0.75rem;
      font-size: 1.2em;
    }
  }

  .example-list {
    border-radius: 8px;
    padding: 0 12px;
    margin: 8px 0;
  }

  p {
    line-height: 1.6;
    color: $high-emphasis;
    margin: 12px 0;
  }

  ul,
  ol {
    padding-left: 24px;

    li {
      padding: 6px 0;
      // line-height: 1.5;
    }
  }

  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin: 20px 0;

    .feature-item {
      border: 1px solid $low-emphasis;
      padding: 16px;
      border-radius: 8px;

      h4 {
        margin: 0 0 8px 0;
        border-bottom: 1px solid $divider-bg;
        padding-bottom: 8px;
      }

      p {
        margin: 0;
      }
    }
  }
}

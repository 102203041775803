@import "../styles/colors";

.sidebar-toggle {
  display: none; /* Hidden by default */
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.046); /* Matching the sidebar color */
  //   color: white;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer; /* Makes it clear it's clickable */
}

.collapsed {
  max-width: 0;
  overflow: hidden;
}

.sidebar {
  z-index: 1;
  color: white;
  height: 100%;
  max-width: 250px;
  width: 100%;
  background: $sidebar-bg;

  .navbar-brand {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    font-weight: 500;
    img.logo {
      height: 32px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .user-avatar {
    width: 30px;
  }
  .orgd {
    gap: 10px;
  }

  .in-sidebar-toggle {
    font-size: 25px;
    padding: 5px;
  }

  .cntnt {
    padding: 8px 15px 0;
    height: 100%;
    .tabs {
      // margin-top: 20px;
      padding: 20px 2px;
      gap: 5px;
      .active {
        background-color: rgba(255, 255, 255, 0.18);
        &:hover {
          background-color: rgba(255, 255, 255, 0.18);
        }
      }
      a {
        font-size: 14px;
        background-color: transparent;
        color: white;
        border-radius: 5px;
        padding: 15px 15px;
        border: none;
        gap: 15px;
        // max-width: 200px;
        width: 100%;
        font-weight: 600;
        text-align: left;
        &:hover {
          background-color: rgba(242, 242, 242, 0.059);
        }
      }
    }
    h3 {
      margin: 0 0 10px;
    }
  }
  .h3 {
    font-size: 20px;
  }
  .h4 {
    font-size: 15px;
  }

  .bottom {
    color: $med-emphasis;
    height: 60px;
    margin-top: auto;
    border-top: 0.5px solid $divider-bg;
    .user-sec {
      gap: 10px;
      h4 {
        font-size: 13px;
        font-weight: 500;
      }
    }
    button {
      background-color: transparent;
      color: white;
      border-radius: 5px;
      padding: 8px 12px;
      border: none;
      cursor: pointer;
      &:hover {
        background-color: rgba(242, 242, 242, 0.167);
      }
    }
  } /* Org selector container */
  .org-selector {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border-radius: 5px;
    margin: 1rem 0; /* Spacing above/below */
    padding: 0.5rem;
    color: #fff;
    font-size: 14px;

    .MuiOutlinedInput-root {
      color: white;
      border: 1px solid $low-emphasis;
    }

    .MuiInputLabel-root {
      color: white;
      background-color: inherit;
    }

    .org-dropdown-container {
      display: flex;
      align-items: center;
      .business-icon {
        color: white;
        margin-right: 0.5rem;
      }
      .org-dropdown {
        flex: 1;
        background: transparent;
        color: #fff;
        border: none;
        outline: none;
        font-weight: 600;
        font-size: 14px;
        appearance: none; /* Hide default arrow in some browsers */

        option {
          color: #000; /* so the dropdown text is visible when open */
        }
      }
      .dropdown-arrow {
        margin-left: 0.5rem;
        color: white;
        font-size: 12px;
      }
    }

    .add-modify-link {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      i.material-icons {
        font-size: 14px;
        margin-right: 5px;
      }
      a {
        color: white;
        text-decoration: none;
        font-size: 14px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  /* Adjust based on your responsive breakpoint */
  .sidebar {
    position: fixed;
    max-width: 250px; /* Original max width */
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }
  .upper {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .collapsed {
    transform: translateX(0);
  }

  .sidebar-toggle {
    display: flex;
  }
}

@import "../styles/colors";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  height: 60px;
  background-color: black;
  color: white;

  .navbar-brand {
    display: flex;
    align-items: center;
    color: white;
    font-size: 18px;
    font-weight: 500;

    img.logo {
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
  .navbar-avatar {
    align-items: flex-end;
  }

  .navbar-mobile {
    display: none; // Hidden by default
  }

  .navbar-links {
    display: flex;
    align-items: center;
    gap: 55px;

    a,
    button {
      text-decoration: none;
      color: white;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
    }

    .cta-btn {
      padding: 12px 36px;
      color: $high-emphasis;
      //   background-color: $primaryColor; // Use variable for color
    }

    &.open {
      display: flex;
    }
    .user-info {
      position: relative;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .dashboard-link {
      color: $button-color;
      border: 1px solid $button-color;
      padding: 12px 10px;
      border-radius: 8px;
      max-width: 200px;
      &:hover {
        background-color: #22deff39;
      }
    }
    .dropdown-menu {
      position: absolute;
      gap: 20px;
      width: 90%;
      min-width: 200px;
      max-width: 300px;
      top: 100%;
      right: 0;
      background-color: black;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      button,
      a {
        padding: 10px;
        width: 100%;
        text-decoration: none;
        color: black;
        &:hover {
          background-color: $panel-bg;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .navbar {
    padding: 0 30px;
    justify-content: center;
    position: relative;
    .navbar-mobile {
      display: block;
      position: absolute;
      left: 30px;
    }

    .navbar-links {
      position: absolute;
      top: 60px;
      left: 0;
      background-color: #fff;
      flex-direction: column;
      width: 100%;
      display: none;
      gap: 0px;

      &.open {
        z-index: 1;
        display: flex;
        padding: 0 0 10px;
        box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2); // Only shadow beneath the container

        background-color: $panel-bg;
        a {
          width: 100%;
          height: 100%;
          padding: 20px 0;
        }
      }
    }
  }
}

// Assuming the colors and base styles are imported
@import "../styles/colors";

.contact-us-container {
  max-width: 800px;
  width: 100%;
  margin: 40px auto;
  background-color: $background-color-b;
  //   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  h1 {
    color: $high-emphasis;
    text-align: center;
    margin-bottom: 40px;
  }
  .captcha-msg {
    font-size: 13px;
    color: $high-emphasis;
    text-align: center;
    a {
      color: $button-color;
    }
  }

  .contact-form {
    display: grid;
    grid-gap: 30px;

    .form-group {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 5px;
        color: $high-emphasis;
      }

      input[type="text"],
      input[type="email"],
      input[type="url"],
      textarea,
      select {
        background-color: inherit;
        font-family: inherit;
        padding: 15px;
        border: 1px solid #fff;
        padding-right: 25px; // Added extra padding to keep the caret from the edge
        border-radius: 4px;
        font-size: 16px;
        color: $high-emphasis;

        &:focus {
          border-color: $high-emphasis;
          outline: none;
        }
      }

      textarea {
        resize: vertical;
      }
    }

    .submit-btn {
      margin: auto;
      min-width: 100px;
      padding: 10px 20px;
      background-color: $high-emphasis;
      color: black;
      font-size: 16px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: darken($high-emphasis, 10%);
      }
    }
  }
  .success-message {
    text-align: center;
    font-size: 24px;
    margin-top: 50px;
    gap: 30px;
    line-height: 2rem;
    color: $high-emphasis;

    .material-icons {
      vertical-align: middle;
      font-size: 48px;
      color: green;
    }
  }
  .grecaptcha-badge {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  .contact-us-container {
    width: 90%;
  }
}

@import "../../styles/colors";

.blog {
  width: 90%;
  max-width: 900px;
  margin: auto;
  line-height: 2.1rem;
  color: $high-emphasis;
  padding: 40px 0;
  img {
    width: 100%;
    max-width: 650px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 20px 0;
  }
  .levels-img {
    max-width: unset;
  }

  h1 {
    font-size: 50px;
    line-height: 4rem;
  }
  h2 {
    font-size: 35px;
    margin: 40px 0 20px;
    line-height: 2.5rem;
  }
  h3 {
    font-size: 20px;
    margin: 30px 0 0;
  }
  p {
    font-size: 18px;
    margin: 30px 0;
    .bold {
      font-weight: 700;
    }
  }

  a:visited {
    color: $colored-emphasis;
  }
}
@media (max-width: 1200px) {
  .blog {
    h1 {
      font-size: 38px;
      line-height: 3rem;
    }
  }
}

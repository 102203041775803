@import "../styles/colors";

.chat-container {
  display: flex;
  height: 100vh;
  background-color: #0f0f0f;
  color: #e0e0e0;
  font-family: "Inter", sans-serif;
  overflow: hidden;
  position: relative;
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
.navbar-brand {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 500;

  img.logo {
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
  }
}

/* SIDEBAR */
.chat-sidebar {
  width: 100%;
  max-width: 250px;
  background: $sidebar-bg;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  transition: transform 0.3s ease, width 0.3s ease;

  .navbar-brand {
    flex-shrink: 0;
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex: 1;
    overflow: hidden;
    margin: 16px 0;

    .new-chat-btn {
      flex-shrink: 0;
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      font-size: 15px;
    }

    .sidebar-title {
      flex-shrink: 0;
    }

    .chat-list {
      flex: 1;
      overflow-y: auto;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-right: 6px;

      // Hide scrollbar by default
      &::-webkit-scrollbar {
        width: 6px;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #333;
        border-radius: 3px;

        &:hover {
          background: #444;
        }
      }

      // Show scrollbar on hover or when scrolling
      &:hover::-webkit-scrollbar,
      &::-webkit-scrollbar:hover,
      &::-webkit-scrollbar-thumb:hover {
        opacity: 1;
      }
    }
  }

  .sidebar-footer {
    flex-shrink: 0;
    margin-top: auto;
    padding-top: 16px;
    border-top: 1px solid $divider-bg;
    gap: 13px;
    a {
      font-size: 14px;
      gap: 10px;
      color: $med-emphasis;
      &:hover {
        color: $high-emphasis;
      }
      i {
        font-size: 18px;
      }
    }
    .MuiButton-root {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .back-dashboard-btn {
      color: #fff;
      justify-content: flex-start;
      text-transform: none;
      padding-left: 0;
      width: 100%;
      &:hover {
        background: none;
        text-decoration: underline;
      }

      svg {
        font-size: 10px;
        margin-right: 5px;
      }
    }
    .user-sec-cnt {
      max-height: 100px;
      width: 100%;
      button {
        background-color: transparent;
        color: white;
        border-radius: 5px;
        padding: 8px 12px;
        border: none;
        cursor: pointer;
        &:hover {
          background-color: rgba(242, 242, 242, 0.167);
        }
      }
      .user-sec {
        gap: 10px;
        h4 {
          font-size: 13px;
          font-weight: 500;
          margin: 0;
        }
        .user-avatar {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .new-chat-btn {
    background: $button-bg;
    color: #fff;
    text-transform: none;

    svg {
      font-size: 18px;
    }

    &:hover {
      background: $button-bg-hover;
    }
  }

  .sidebar-title {
    font-size: 12px;
    text-transform: uppercase;
    color: #888;
    margin-top: 10px;
  }

  .chat-item {
    background: $button-bg-darker;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &:hover {
      background: $button-bg;
    }

    &.active {
      background: #444;
    }

    p {
      margin: 0;
      color: #fff;
      font-size: 14px;
    }
    span {
      font-size: 10px;
      color: #888;
      display: block;
      margin: 2px 0;
    }

    .repo-tag {
      font-size: 10px;
      color: #888;
      padding: 2px 6px;
      border: 1px solid #333;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.05);
      display: inline-block;
      margin-top: 4px;
    }

    .chat-item-content {
      flex: 1;
      min-width: 0;
    }

    .delete-icon {
      opacity: 0.2;
      margin-left: auto;
      font-size: 10px;
      transition: opacity 0.2s;
      padding: 4px;
      display: flex;
      align-items: flex-end;
      border-radius: 4px;
      color: #666;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
      }
    }

    &:hover {
      .delete-icon {
        opacity: 0.7;
      }
    }
  }

  .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .in-sidebar-toggle {
    display: none; // Hidden by default
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 8px;

    .material-icons {
      font-size: 24px;
    }
  }

  &.collapsed {
    transform: translateX(-100%);
    width: 0;
  }
}

/* MAIN CHAT AREA */
.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: $main-bg;
}

/* Centered content for no currentChat */
.centered-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .hello-text {
    font-size: 40px;
    margin-bottom: 20px;
    font-weight: 600;
    color: #fff;
  }

  .repo-dropdown {
    min-width: 240px;
    margin-top: 10px;
    color: #fff;

    .MuiInputLabel-root {
      color: #fff;
    }

    .MuiOutlinedInput-root {
      background: $button-bg-darker;

      color: #fff;
      fieldset {
        border: 1px solid $button-bg;
      }
      &:hover fieldset {
        border-color: #666;
      }
      &.Mui-focused fieldset {
        border-color: $button-bg;
      }
    }
    .MuiSvgIcon-root {
      color: #ccc;
    }
  }

  .connect-repos-btn {
    background: #444;
    color: #fff;
    text-transform: none;
    &:hover {
      background: #555;
    }
  }
  .ghb {
    font-size: 12px;
    color: #888;
    a {
      color: #888;
      text-decoration: underline;
    }
  }
}

/* CHAT HEADER */
.chat-header {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $sidebar-bg;
  border-bottom: 1px solid $divider-bg;
  align-items: flex-start;

  .chat-title {
    font-size: 18px;
    color: #fff;
  }

  .repo-tag {
    font-size: 14px;
    color: #ccc;
    padding: 4px 10px;
    border: 1px solid #333;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    margin-top: 4px;
  }

  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      background: #000000;
      color: #fff;
      box-shadow: none;

      .MuiSelect-select {
        color: #fff !important;
      }

      fieldset {
        border-color: #333;
      }

      &:hover fieldset {
        border-color: #444;
      }

      &.Mui-focused fieldset {
        border-color: #1e88e5;
      }

      &.Mui-disabled {
        background: rgba(21, 21, 21, 0.7);
        color: rgba(255, 255, 255, 0.5);
        fieldset {
          border-color: rgba(51, 51, 51, 0.5);
        }
      }
    }

    .MuiInputLabel-root {
      color: #888;

      &.Mui-focused {
        color: #1e88e5;
      }

      &.Mui-disabled {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    .MuiSvgIcon-root {
      color: #888;
    }
  }
}

/* Add these styles for the dropdown menu */
.MuiPopover-paper.MuiMenu-paper {
  background: #151515 !important;
  color: #fff !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) !important;

  .MuiListSubheader-root {
    color: #888 !important;
    font-size: 13px;
    // font-weight: 600;
    line-height: 1.2;
    // text-transform: uppercase;
    padding: 5px 5px 5px 10px;
    background: #151515 !important;
    // margin-bottom: 4px;
  }

  .MuiMenuItem-root {
    color: #fff !important;

    &:hover {
      background-color: #222 !important;
    }

    &.Mui-selected {
      background-color: #2a2a2a !important;

      &:hover {
        background-color: #333 !important;
      }
    }
  }
}

/* CHAT MESSAGES */
.chat-messages {
  flex: 1;
  width: 100%;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 900px;
  min-width: 300px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 3px;
  }
}

.empty-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0 20px;
  gap: 10px;
}

/* Remove bubble backgrounds; just text alignment */
.message {
  font-size: 14.5px;
  line-height: 1.6;
  background: none !important;
  margin-bottom: 14px;
  padding: 0;
  color: #e0e0e0;

  &.user-message {
    align-self: flex-end;
    color: #ffffff;
    background: #2a2a2a !important;
    padding: 10px 18px !important;
    border-radius: 12px !important;
    max-width: 80%;
  }
  &.assistant-message {
    align-self: flex-start;
    color: #fff;
    position: relative;
    padding-left: 40px;
    max-width: 90%;

    .message-avatar {
      position: absolute;
      left: -10px;
      top: 3px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 90%;
        height: 90%;
        object-fit: cover;
      }
    }
  }

  .processing-indicator {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    font-style: italic;

    .progress-container {
      position: relative;
      width: 20px;
      height: 20px;

      .MuiCircularProgress-root {
        position: absolute;
        left: 0;
        top: 0;
      }

      .stop-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        cursor: pointer;
        color: #fff;
        opacity: 0.7;
        transition: opacity 0.2s;
        z-index: 1;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .message-content {
    word-break: break-word;
  }

  .message-text {
    width: 100%;
    overflow-x: auto;

    pre {
      white-space: pre-wrap;
      word-break: break-word;
      font-family: inherit;
      margin: 0;
    }
  }

  .user-message {
    .message-text {
      white-space: pre-wrap;
    }
  }

  .message-text {
    p {
      margin: 0.5rem 0;
    }
    code {
      background: rgba(255, 255, 255, 0.074);
      padding: 0em 0.4em;
      border-radius: 4px;
      font-family: "Courier New", monospace;
      font-size: 0.9em;
      display: inline-block;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    /* Update styling for multiline code blocks */
    code.language-javascript,
    code.language-jsx,
    code.language-typescript,
    code.language-tsx,
    code.language-css,
    code.language-html,
    code.language-json,
    code.language-python,
    code.language-bash,
    code.language-shell {
      display: block;
      background: #1e1e1e;
      padding: 1rem;
      border-radius: 6px;
      margin: 0.75rem 0;
      overflow-x: auto;
      border: 1px solid #333;
    }
    pre {
      margin: 0.75rem 0;
      padding: 0;
      background: none;
      border-radius: 6px;
      overflow: hidden;

      div {
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }
    /* Keep inline code styling */
    code:not(pre code) {
      background: rgba(255, 255, 255, 0.074);
      padding: 0 0.4em;
      border-radius: 4px;
      font-family: "Courier New", monospace;
      font-size: 0.9em;
      display: inline-block;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    a {
      color: #82b1ff;
      text-decoration: underline;
      &:hover {
        color: #64a2ff;
      }
    }
    hr {
      margin: 1rem 0;
      border: none;
      border-top: 1px solid #444;
    }
  }
}

/* CHAT INPUT: always visible, smaller, thin white border */
.chat-input {
  margin: 16px auto;
  padding: 6px 10px;
  width: 95%;
  max-width: 900px;
  min-width: 300px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;

  .input-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .MuiFormHelperText-root {
      position: absolute;
      bottom: -20px;
      left: 0;
      color: #d32f2f;
      margin: 0;
    }
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .message-input {
    .MuiOutlinedInput-root.Mui-disabled {
      opacity: 0.5;

      .MuiOutlinedInput-input {
        color: rgba(255, 255, 255, 0.5);

        &::placeholder {
          color: rgba(255, 255, 255, 0.3) !important;
        }
      }
    }
  }

  .send-button.Mui-disabled {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  .MuiOutlinedInput-root {
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: #d32f2f;
      }
    }
  }
}

.message-input .MuiOutlinedInput-root {
  background: transparent !important;
  color: #fff !important;
  fieldset {
    border: none !important;
  }
  .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 1.4 !important;
  }
  /* Placeholder text color */
  .MuiInputBase-input::placeholder {
    color: #888 !important;
  }
}

.send-button {
  min-width: 60px;
  margin-left: 8px;
  color: #fff !important;
  text-transform: none !important;
  border: none !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.ghost-loading {
  align-self: flex-start;
  padding-left: 40px;
  margin-top: 16px;
  width: 100%;
  position: relative;

  .message-avatar {
    position: absolute;
    left: -10px;
    top: 3px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 90%;
      height: 90%;
      object-fit: cover;
    }
  }

  .ghost-bars {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
    width: 100%;

    .ghost-bar {
      height: 14.5px;
      background: #2a2a2a;
      border-radius: 6px;
      animation: pulse 1.5s infinite;

      &:nth-child(1) {
        width: 90%;
      }
      &:nth-child(2) {
        width: 85%;
      }
      &:nth-child(3) {
        width: 60%;
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

/* Add these styles near the top of the file */
.sidebar-toggle {
  display: none; // Hidden by default
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background: #2a2a2a;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;

  .material-icons {
    font-size: 24px;
  }
}
/* CHAT INPUT: always visible, smaller, thin white border */
.chat-suggestions {
  gap: 10px;
  margin: 20px auto;
  padding: 20px;
  width: 95%;
  max-width: 900px;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  scrollbar-width: thin;
  scrollbar-color: #333 transparent;

  /* Webkit scrollbar styling */
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 3px;

    &:hover {
      background-color: #444;
    }
  }

  .suggestion-button {
    flex: 1;
    max-width: 300px;
    overflow: hidden;
    white-space: normal;
    line-height: 1.2;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    color: #b8b8b8;
    border-color: #333;
    padding: 8px 16px;
    font-size: 14px;
    text-align: left;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      border-color: #666;
      color: #fff;
    }
    &.Mui-disabled {
      opacity: 0.2;
      color: white;
      border-color: white;
    }
  }
}

/* Add media query for mobile responsiveness */
@media (max-width: 768px) {
  .chat-suggestions {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;

    .suggestion-button {
      flex: 0 0 auto;
      min-width: 250px;
    }
  }

  .sidebar-toggle {
    display: block;
  }

  .chat-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;

    .in-sidebar-toggle {
      display: block;
    }

    // Add overlay background when sidebar is open
    &:not(.collapsed)::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }
  .chat-container {
    max-width: 100vw;
  }

  .chat-main {
    width: 100%;
    max-width: 100vw;
  }

  .chat-header {
    // padding-left: 60px;
    align-items: center;
    text-align: center;

    .chat-title {
      width: 100%;
      padding: 0 40px;
      box-sizing: border-box;
    }

    .repo-tag {
      align-self: center;
    }
  }

  /* CHAT MESSAGES */
  .chat-messages {
    gap: 8px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: 2px;
    }
    ol {
      padding-left: 1.5rem;
    }
    ul {
      padding-left: 1.5rem;
    }
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1.1rem;
    }
  }
  /* Remove bubble backgrounds; just text alignment */
  .message {
    &.user-message {
      padding: 0;
      max-width: 98%;
    }

    &.assistant-message {
      padding: 0;
      max-width: 98%;
      .message-avatar {
        display: none;
      }
    }
  }
  .message-input .MuiOutlinedInput-root {
    padding: 12px 6px;
  }

  .chat-input {
    width: 95%;
    margin: 16px auto;
    max-width: 100%;
    min-width: 0;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .input-container {
      flex: 1;
      min-width: 0;
    }

    .message-input {
      width: 100%;

      .MuiOutlinedInput-root {
        padding: 8px;
        .MuiOutlinedInput-input {
          font-size: 16px !important;
        }
      }
    }

    .send-button {
      flex-shrink: 0;
      min-width: 40px;
      margin-left: 0;
    }

    .MuiButtonBase-root {
      min-width: 40px;
      height: 100%;
    }
  }

  .ghost-loading {
    padding-left: 0;
    .message-avatar {
      display: none;
    }
  }
}

@import "flexbox";
@import "colors";

text {
  color: $primary-color;
}

.content {
  flex: 1;
  background-color: $background-color-b;
}
a {
  text-decoration: none;
}

.pb-grd {
  background: var(
    --gradient,
    linear-gradient(90deg, #ea30fa 0%, #778efc 50.5%, #10e2ff 96%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-grd2 {
  background: linear-gradient(92deg, #11549a 21.7%, #913ff9 68.61%);
}

.shadow-box {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.cta-btn {
  border-radius: 12px;
  background: $button-color;
  color: #ffffff; // White text
  cursor: pointer;
  border: none;
  padding: 16px;
  font-size: 15px;
  font-weight: 500;

  &:hover {
    background-color: #036ad7; // Darker blue on hover
    text-decoration: none !important;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

.user-avatar {
  width: 40px;
  border-radius: 50%;
  height: auto;
  object-fit: cover;
}

.tos-container {
  padding: 60px 30px;
  margin: auto;
  max-width: 1200px;
  color: $high-emphasis;
}

@import "../styles/colors";

// Base styles
.home {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  gap: 90px;
  color: $high-emphasis;
  overflow: hidden;
  text-align: center;
  background-color: $background-color-b;
  box-sizing: border-box;

  // Typography
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: 700;
  }

  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 56px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 24px;
    line-height: 2rem;
    font-weight: 600;
  }

  p {
    line-height: 1.6em;
    margin: 0;
    text-align: center;
    color: $high-emphasis;
  }

  // Common classes
  .bold {
    font-weight: 700;
  }
  .panel-bg {
    background-color: $panel-bg;
    border-radius: 8px;
  }
  .shadow-box {
    background-color: $panel-bg;
  }
  .home-icn {
    color: $colored-emphasis;
  }
  .heading {
    gap: 20px;
  }
  .lim-width {
    max-width: 1200px;
    margin: auto;
  }

  // Section styles
  .section {
    width: 100%;
    gap: 15px;
    .cta-btn-home {
      width: 200px;
    }
  }

  // Video container
  .video-container {
    position: relative;
    video {
      display: block;
      cursor: pointer;
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: 0.5px solid rgb(29, 28, 28);
    }

    .play-button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: white;
      background-color: rgba(42, 47, 76, 0.5);
      border-radius: 8px;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // Hero section
  .hero {
    gap: 30px;

    h3 {
      padding-bottom: 5px;
    }

    .subtext {
      font-size: 16px;
      padding-bottom: 10px;
      text-align: left;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .hero-left {
    min-width: 570px;
    padding: 10px 15px;
    gap: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: left;
  }

  .main-heading {
    padding: 12px 0 5px;
    line-height: 0.9em;
  }

  .main-subheading {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
  }

  .pre {
    opacity: 0.7;
    font-size: 25px;
    padding-bottom: 0;
  }

  .p-light {
    color: #909090;
    margin: 6px 0 0;
    font-size: 13px;
  }

  // Dot background
  .dot-bg {
    position: relative;
    width: 100%;
    background-repeat: repeat;
    background-position: center;
    mix-blend-mode: lighten;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-image: url("../assets/dot-bg.png");
      animation: pulseOpacity 4s infinite ease-in-out;
      z-index: -1;
    }
  }

  @keyframes pulseOpacity {
    0%,
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 0.35;
    }
  }

  // Stats section
  .stats-section {
    gap: 30px;

    .stats {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      text-align: center;
      align-items: center; // Stretches items to fill the container height
      gap: 10px;

      .shadow-box {
        padding: 20px;
        height: 135px;
        max-width: 400px;
        background-color: $panel-bg;
        min-height: 175px; // Ensures a consistent height across all items
        width: 100%; // Takes full width of its flex container part
      }

      .stat-num {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .stat-desc {
        font-size: 15px;
      }
    }
  }

  // Issue section
  .issue-section {
    gap: 55px;
    padding: 20px 0 40px;
    max-width: 1000px;

    .tkt-pill {
      border: 1px solid;
      border-radius: 40px;
      padding: 7px 25px;
      gap: 10px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      h4 {
        font-size: 18px;
      }
    }

    .bug {
      color: #ed7142;
      border-color: #ed7142;
    }
    .ft {
      color: #00ffab;
      border-color: #00ffab;
    }
    .en {
      color: #00d6ff;
      border-color: #00d6ff;
    }
    .qa {
      color: #cb6ce6;
      border-color: #cb6ce6;
    }

    .panels {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: stretch;
      gap: 20px;

      @media (max-width: 1200px) {
        justify-content: center;
      }
    }

    .panel {
      background-color: $panel-bg;
      padding: 50px 25px;
      align-items: center;
      border-radius: 10px;
      flex-basis: calc(50% - 10px);
      box-sizing: border-box;
      height: auto;
      gap: 40px;

      &.full-width {
        flex-basis: 100%;
      }

      @media (max-width: 1200px) {
        flex-basis: 100%;
      }

      p {
        color: $high-emphasis;
        font-size: 18px;
        margin-top: 10px;
      }
    }

    .feat-sec {
      color: $high-emphasis;
      padding: 30px 10px;
      justify-content: space-evenly;

      @media (max-width: 1200px) {
        flex-direction: column;
        flex-basis: calc(100% - 20px);
      }

      img {
        height: 70px;
      }
      h4 {
        margin-top: 12px;
      }

      .feat-dsc {
        font-size: 15px;
        line-height: 2rem;
        color: $high-emphasis;
        p {
          font-size: 15px;
          color: $high-emphasis;
        }
      }

      ul {
        margin-top: 10px;
        list-style-type: none;
        padding: 0;
      }

      li {
        position: relative;
        padding-left: 30px;
        display: flex;
        align-items: center;
        line-height: 2rem;
        gap: 10px;
        text-align: left;
      }

      p {
        text-align: left;
      }
    }

    .cta-panel {
      text-align: center;
      margin-top: 40px;
      gap: 10px;

      p {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }

  // PR section
  .pr-section {
    gap: 40px;

    .panels {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 20px;

      .code-review-panel {
        grid-column: 1 / -1;
        display: flex;
        align-items: center;
        gap: 30px;
        box-sizing: border-box;

        h3 {
          font-size: 40px;
        }

        .text-section {
          flex: 0 0 40%;
          gap: 20px;
        }

        .image-section {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: center;

          .text-section {
            flex: auto;
          }

          .image-section img {
            width: 100%;
            height: auto;
          }
        }
      }

      .cmn-panel {
        border-radius: 10px;
        padding: 30px;
        height: auto;
        background-color: $panel-bg;

        @media (max-width: 1200px) {
          padding: 20px 7px;
        }
      }

      .testing-panel {
        grid-column: 1;
        gap: 20px;

        img {
          height: 50px;
          margin-bottom: 15px;
        }
      }

      .follow-up-panel {
        grid-column: 2;
        gap: 20px;

        .material-icons {
          font-size: 56px;
          margin-bottom: 15px;
        }
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr;

        .testing-panel,
        .follow-up-panel {
          grid-column: 1;
        }
      }
    }

    .panel {
      h3 {
        color: $colored-emphasis;
        font-size: 24px;
        margin-bottom: 10px;
      }

      p {
        color: $high-emphasis;
        font-size: 16px;
        margin-top: 5px;
      }
    }
  }

  // Interactive section
  .interactive-section {
    gap: 55px;
    padding: 20px 0 40px;
  }

  // Prompter
  .prompter {
    border: 1px solid $high-emphasis;
    border-radius: 50px;
    font-size: 28px;
    width: 95%;
    max-width: 800px;
    overflow: hidden;
    margin-bottom: 25px;
    background-color: $background-color-b;

    .window {
      padding: 20px 25px;
      text-align: left;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      overflow: hidden;
      white-space: nowrap;
    }

    .Typewriter__wrapper,
    .Typewriter__cursor {
      display: inline-block;
    }

    .Typewriter__cursor {
      width: 1ch;
    }
  }

  // Integration section
  .intgr-section {
    padding: 40px 0 20px;
    // gap: 55px;
    justify-content: space-between;
    width: 100%;
    text-align: left;

    .boxes {
      gap: 20px;
    }

    .box {
      min-width: 400px;
      padding: 20px 30px;
      gap: 20px;

      p {
        margin-top: 15px;
      }
    }

    .lefty {
      gap: 40px;
      p {
        text-align: left;
      }
    }

    .logos {
      gap: 40px;
      img {
        height: 50px;
      }
    }

    .mod-1 {
      gap: 10px;
      width: auto;
      background-color: transparent;
      border: 1px solid $high-emphasis;

      &:hover {
        background-color: rgb(49, 49, 49);
      }

      img {
        height: 20px;
      }
    }
  }

  // Security section
  .sec-section {
    gap: 20px;
    width: 100%;

    .boxes {
      margin: 40px 0 50px;
      justify-content: space-around;
      width: 100%;
    }

    .box {
      max-width: 300px;
      min-width: 200px;
      width: 100%;
      flex: 1;

      .graphic {
        margin-bottom: 30px;
        width: auto;

        .rel-graphic-svg {
          transform: rotate(-21.34deg);
        }

        svg {
          height: 70px;
          width: auto;
        }
      }

      p {
        height: 50px;
      }
    }
  }

  // Organizations section
  .orgs {
    width: 100%;
    gap: 40px;

    .logos {
      flex: none;
      overflow: hidden;
      position: relative;
      width: auto;
      gap: 40px;
      justify-content: space-between;

      ul {
        display: flex;
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        margin: 0;
        animation: scroll-left 30s linear infinite;
      }

      li {
        list-style: none;
        flex: none;
      }
    }

    img {
      filter: invert(100%) grayscale(100%);
      opacity: 0.4;
      margin: 0 150px;
    }
  }

  @keyframes scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-33.3333%);
    }
  }

  // Pricing section
  .pricing {
    gap: 50px;
    color: $high-emphasis;
    .most-popular {
      position: relative;
    }

    .popular-banner {
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ff9800; /* Orange color */
      color: white;
      padding: 5px 10px;
      font-size: 0.9rem;
      font-weight: bold;
      border-radius: 5px;
      text-transform: uppercase;
      z-index: 1;
    }

    .shadow-box {
      width: 100%;
      padding: 30px 10px;
      min-width: 350px;
      max-width: 420px;
    }

    .boxes {
      width: 100%;
      gap: 10px;
      justify-content: space-around;
    }

    .features {
      padding: 40px 0;

      .feat {
        gap: 10px;
        padding: 20px;
      }
    }
    .crd {
      border-bottom: 1px dotted grey;
      cursor: "pointer";
      color: "#fff";
    }

    p {
      text-align: left;
      font-size: 18px;
    }

    .price {
      margin: 20px 0;
      font-size: 22px;
      font-weight: 600;
      color: $high-emphasis;
    }

    .subscript {
      font-size: 15px;
      font-weight: 600;
      color: #d1cbcb;
    }

    .divider {
      background-color: $low-emphasis;
      min-height: 1px;
      min-width: 305px;
    }

    .cta-btn {
      width: 200px;
      height: 60px;
      font-size: 18px;
    }

    .contact-btn {
      background-color: transparent;
      border: 1px solid $high-emphasis;
      color: $high-emphasis;

      &:hover {
        background-color: rgb(49, 49, 49);
      }
    }
  } /* Video background styling */
  .bg-section {
    position: relative;
    width: 100%;
    height: 400px; /* Adjust as needed */
    overflow: hidden;
  }

  .bg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Ensure it fills the parent container */
    object-fit: cover; /* Ensures the video covers the container */
    /* You can add z-index if needed */
    /* z-index: -1; */
  }

  /* Overlay container */
  .bg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Match the container height */
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    /* z-index: 1; */ /* Ensure overlay is above the video */
  }

  /* Overlay text styling */
  .overlay-text {
    background-color: rgba(0, 0, 0, 0.231);
    color: white;
    padding: 5px 7px;
    font-size: 2rem;
    text-align: center;
    margin: 0;
  }
}

// Responsive styles
@media (max-width: 1200px) {
  .home {
    min-width: 325px;
    gap: 50px;
    padding: 40px 7px;

    // Typography responsive
    h1,
    h2,
    h3,
    h4,
    p {
      text-align: center;
    }
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 39px;
    }
    h3 {
      font-size: 35px;
    }
    h4 {
      font-size: 30px;
    }
    p {
      line-height: 1.2em;
      font-size: 15px;
    }

    .heading h4 {
      font-size: 22px;
    }
    .main-subheading {
      font-size: 25px;
    }
    .pre {
      padding-bottom: 10px;
    }

    // Layout responsive
    .lim-width {
      max-width: 720px;
    }
    .dot-bg {
      padding: 33px 0 20px;
    }

    // Hero responsive
    .hero {
      flex-wrap: wrap;

      .subtext {
        font-size: 15px;
        text-align: center;
        line-height: 1.3rem;
      }
    }

    .hero-left {
      min-width: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
      }
    }

    // Stats responsive
    .stats {
      flex-direction: column;
      gap: 20px;
    }

    .prompter {
      font-size: 12px;
      .window {
        padding: 10px 15px;
      }
    }

    // Sections responsive
    .interactive-section {
      gap: 30px;

      .panels {
        flex-direction: column;
      }
      .panel {
        margin: 0 10px;
      }
    }

    .testing-section {
      gap: 30px;
    }

    .intgr-section {
      gap: 20px;
      flex-direction: column;

      .lefty {
        align-items: center;
        p {
          text-align: center;
        }
      }

      .box {
        min-width: 300px;
        max-width: 500px;
        padding: 40px 25px;
        min-height: 150px;
      }
    }

    .sec-section {
      flex-direction: column;
      gap: 10px;
      justify-content: center;

      .boxes {
        margin: 20px 0 0;
        flex-direction: column;
      }

      .box p {
        height: 70px;
      }
    }

    .orgs {
      gap: 10px;
      img {
        margin: 0 80px;
      }
    }

    // Pricing responsive
    .pricing {
      gap: 50px;

      .shadow-box {
        width: 100%;
        padding: 30px 0;
        min-width: 300px;
        max-width: 400px;
      }

      .boxes {
        flex-direction: column;
        gap: 40px;
      }
      p {
        font-size: 16px;
      }
    }

    // Users section responsive
    .users {
      .boxes {
        flex-wrap: nowrap;
        overflow-x: auto;
        white-space: nowrap;
        padding: 10px 0;
      }

      .shadow-box {
        margin: 0 15px;
      }

      .boxes > .f-col {
        flex: 0 0 auto;
        white-space: normal;
      }
    }
  }
}

@import "../styles/colors";

.user-management {
  padding: 40px 20px;
  width: 100%;
  max-width: 1000px;

  .section-heading {
    margin: 0 0 12px 0;
    font-size: 1.25rem;
    font-weight: 600;
  }

  .add-users {
    margin-bottom: 20px;

    .add-user {
      gap: 12px;
    }
  }

  .MuiSelect-select {
    font-size: 14px;
    padding: 6px 12px;
    color: $high-emphasis;
    border: 1px solid $button-bg-darker;
  }
  .MuiSelect-icon {
    color: $high-emphasis;
  }
  .MuiSvgIcon-root {
    color: $low-emphasis;
  }

  .error {
    color: #d32f2f;
    margin: 8px 0;
    font-size: 13px !important;
    gap: 5px;
  }

  .refresh-btn {
    margin-left: auto;
    font-size: 14px;
    color: $low-emphasis;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 14px;

    .user-row {
      gap: 10px;
      a {
        text-decoration: none;
      }
    }

    .user-avatar {
      width: 25px;
      height: 25px;
    }
    .username {
      font-weight: 600;
      font-size: 14px;
      gap: 10px;
    }

    .self-role {
      font-size: 12px;
      font-weight: 500;
    }

    .username-remove {
      text-decoration: line-through;
      color: #d32f2f;
    }

    .username-add {
      color: #03cc00;
    }

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      padding: 0 8px;
      border-radius: 5px;
      font-size: 11px;
      font-weight: 500;

      &.owner {
        background: #054472;
        color: #ffffff;
      }
    }

    .remove-btn {
      color: #d32f2f;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 4px;
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
  .refresh {
    color: $med-emphasis;
    .MuiSvgIcon-root {
      color: $med-emphasis;
    }
  }
}

@media (max-width: 768px) {
  .user-management {
    width: 100%;
    padding: 20px 0px;
    table {
      width: 100%;
    }

    .MuiSelect-select {
      font-size: 13px;
      padding: 6px 12px;
    }

    .add-users {
      flex-direction: column;
      gap: 20px;
      .add-user {
        input {
          width: 100%;
          max-width: 600px;
        }
        margin: auto;
      }
    }
    .refresh-btn {
      margin-top: 20px;
      margin: auto;
    }
  }
}

@import "../styles/colors.scss";

.how-it-works {
  max-width: 700px;
  width: 100%;
  color: $high-emphasis;

  h1 {
    color: #1976d2;
  }

  .icon {
    margin-left: auto;
  }

  .accordion {
    width: 100%;
  }

  .accordion-item {
    border-bottom: 1px solid #eee;
    padding: 23px 0;

    &:last-child {
      border-bottom: none;
    }
  }

  .accordion-title {
    width: 100%;
    font-size: 1.1rem;
    font-weight: 500;
    cursor: pointer;
  }
  p {
    width: 100%;
    line-height: 1.8rem;
  }

  .accordion-content {
    padding: 1rem;
    color: $high-emphasis;
  }

  .bold {
    font-weight: bold;
  }

  // Links Styling
  a {
    color: #1976d2;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 768px) {
  .how-it-works {
    padding: 0 10px;
    width: auto;
  }
}

@import "../styles/colors";

.dashboard {
  width: 100%;
  position: relative;
  height: 100vh;
  font-size: 14px;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .main-outer {
    height: 100vh;
    overflow-y: scroll;
    flex-grow: 1;
    width: 100%;
    background-color: $main-bg;
    color: $high-emphasis;
  }

  .MuiTabs-root {
    .MuiTab-root {
      color: $high-emphasis;
    }
    .Mui-selected {
      font-weight: 600;
    }
  }

  .note {
    color: rgb(164, 163, 163);
    font-size: 0.85rem;
    font-weight: 400;
  }

  .main {
    padding: 40px;
    gap: 20px;

    a {
      text-decoration: none;
      color: $high-emphasis;
      border-radius: 5px;

      &:hover {
        text-decoration: none;
      }
    }

    h1 {
      margin: 10px 0;
      font-size: 27px;
    }

    h2 {
      font-size: 20px;
    }

    h4 {
      margin: 20px 0 15px;
    }

    p {
      margin: 5px 0 20px;
      font-size: 14px;
    }

    .directive {
      p {
        line-height: 2rem;

        .code {
          padding: 0.2em 0.4em;
          margin: 0;
          font-size: 85%;
          white-space: break-spaces;
          background-color: #afb8c133;
          border-radius: 6px;
        }
      }
    }

    .title {
      gap: 10px;
    }

    .status-badge {
      font-size: 16px;
      gap: 8px;
      padding-left: 10px;

      .pulsating-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $success; /* Active green color */
        position: relative;
        animation: pulse 2s infinite;
      }

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
        }
        70% {
          box-shadow: 0 0 0 10px rgba(76, 175, 80, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
        }
      }
    }

    .active {
      color: $success;
    }

    .inactive {
      color: rgb(142, 142, 142);
    }

    .logo-header {
      display: none;
    }

    .route {
      font-size: 14px;
      font-weight: 600;
      gap: 7px;

      .icn {
        font-size: 17px;
      }
    }

    .header {
      width: 100%;
      gap: 40px;

      .cta-btn {
        padding: 12px;
        color: white;
        gap: 5px;
      }

      .lefty {
        gap: 20px;
      }

      i {
        font-size: 15px;
        font-weight: 700;
      }
    }

    .page-error {
      color: red;
      width: 100%;
      margin: 60px 0 0 30px;
      gap: 12px;
      font-size: 18px;
    }

    .open-icn {
      font-size: 17px;
    }

    .entities {
      gap: 20px;
      flex-wrap: wrap;

      .entity {
        max-width: 300px;
        min-width: 200px;
        min-height: 80px;
        width: 100%;
        overflow: hidden;
      }
    }

    .main-container {
      gap: 20px;
      width: 100%;

      h3 {
        margin: 5px 0 10px;
      }

      a {
        gap: 20px;
        color: $high-emphasis;
        padding: 25px 15px 25px 25px;
        background-color: rgba(255, 255, 255, 0.065);
        border-radius: 10px;
        font-weight: 600;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
      }
    }

    .org-container {
      text-wrap: wrap;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 10px;
      }

      .entity {
        max-width: 250px;
        min-width: 100px;
      }

      a {
        padding: 15px;
      }

      .name {
        gap: 5px;
        width: 100%;
        overflow: hidden;
      }
    }

    .runs {
      width: 100%;
      gap: 10px;
    }

    table {
      width: 100%;
      border-collapse: separate; // Allows applying border radius
      border-spacing: 0; // Removes space between cells
      margin: 20px 0; // Adds spacing around the table

      a {
        text-decoration: underline;
      }

      thead {
        tr {
          border-radius: 8px; // Rounded corners for the header row
          // background-color: #ebebeb; // Light grey background for the header

          th {
            padding: 18px 15px;
            text-align: left;

            &:first-child {
              border-top-left-radius: 8px; // Rounded corners
            }

            &:last-child {
              border-top-right-radius: 8px;
            }
          }
        }
      }

      tbody {
        tr {
          &:hover {
            background-color: $dark-hover-bg; // Hover effect
          }

          td {
            padding: 18px 15px;
            border-bottom: 1px solid $divider-bg;

            @media (max-width: 768px) {
              padding: 10px 5px;
            }
          }
        }
      }
    }

    .save-btn {
      padding: 10px 20px;
      background-color: $button-bg;
      color: $high-emphasis;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      &:hover {
        background-color: $button-bg-hover;
      }

      &:disabled {
        background-color: #3e3c3c;
        color: rgba(173, 173, 173, 0.321);
        opacity: 0.5;
        cursor: not-allowed;
      }
      a {
        text-decoration: none;
      }
      i {
        font-size: 12px;
        margin-right: 4px;
      }
    }

    input {
      flex: 1;
      height: 36px;
      max-width: 200px;
      padding: 8px 16px;
      border: 1px solid $low-emphasis;
      border-radius: 4px;
      outline: none;
      background-color: $main-bg;
      color: $high-emphasis;

      &:focus {
        border-color: $high-emphasis;
      }
    }

    .MuiInputBase-root {
      color: $high-emphasis;
      border: 1px solid $low-emphasis;
    }
    .MuiInputLabel-root {
      color: $med-emphasis;
      background-color: $main-bg;
    }

    .MuiFormHelperText-root {
      color: $low-emphasis;
    }

    .MuiTabs-flexContainer {
      gap: 20px;
      border-bottom: 1px solid $divider-bg;
    }

    .toggle-section {
      .settings-subtitle {
        font-size: 16px;
        margin-top: 20px;
        margin-bottom: 15px;
        font-weight: 500;
      }

      .MuiFormGroup-root {
        gap: 20px;
      }
      .MuiFormControlLabel-root {
        display: flex;
        // flex-direction: column; // Stack elements vertically
        align-items: flex-start; // Align items to the start
        margin-left: 0;
        margin-right: 0;

        .MuiFormControlLabel-label {
          display: flex;
          align-items: center; // Align label text and switch toggle in a row
          font-size: 16px;
          margin-bottom: 4px; // Adds spacing between the label and subtext
        }

        .toggle-control {
          display: flex;
          align-items: center; // Ensure the switch is aligned with the label text
        }
      }
      .MuiTextField-root {
        margin-left: 50px;
      }

      .toggle-subtext {
        font-size: 14px;
        color: #666;
        margin-top: 4px; // Spacing between the toggle and the subtext
        // margin-left: 40px; // Indent to align with the switch toggle
        line-height: 1.4;
        max-width: 500px; // Limit the width for better readability
      }

      .message-box {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 16px;
        font-size: 14px;
        font-weight: 500;

        &.success {
          color: #0ebb44;
        }

        &.error {
          color: #d32f2f;
        }

        svg {
          margin-right: 4px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main {
      padding: 10px;
      gap: 20px;

      h1 {
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 20px;
        display: flex;
        justify-content: center;
      }

      .header {
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;

        .lefty {
          flex-direction: column;
          gap: 4px;
        }

        .cta-btn {
          padding: 8px;
          font-size: 13px;
        }
      }

      .status-badge {
        padding: 0px;
      }

      .entities {
        justify-content: center;
        align-items: center;
        .entity {
          max-width: unset;
        }
      }

      .logo-header {
        display: flex;
        margin: 7px auto;

        img {
          width: 40px;
          height: auto;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .toggle-section {
        .save-btn {
          padding: 8px 16px;
          font-size: 14px;
        }

        .MuiFormControlLabel-label {
          font-size: 14px;
        }
        .MuiTextField-root {
          margin-left: 0;
        }
      }
    }
  }
  .MuiTab-root {
    text-transform: none;
  }
}

@import "../styles/colors";

.blog-list {
  // padding: 40px 10px;
  margin: 70px 0 50px;
  width: 100%;
  gap: 70px;

  .blog-preview {
    // width: 100%;
    max-width: 350px;
    text-decoration: none;
    color: $high-emphasis;
    h2 {
      margin: 10px 5px;
      font-size: 22px;
    }
    img {
      height: 350px;
      width: 350px;
      object-fit: cover;
      //   max-width: 350px;
      border-radius: 20px;
    }
    h3 {
      font-size: 20px;
      font-weight: 700;
    }
    &:hover {
      scale: 1.01;
      color: $med-emphasis; // Correct hover effect
    }
  }
}

/* styles/flexbox.scss */

.f-col {
  display: flex;
  flex-direction: column;
}

.f-row {
  display: flex;
  flex-direction: row;
}

.frcc {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.f-wrap {
  flex-wrap: wrap;
}

.fccc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aic-jcc {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aic {
  align-items: center;
}

.jcc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.fwrap {
  flex-wrap: wrap;
}

.f-equal {
  flex: 1;
}

.fw {
  width: 100%;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}
